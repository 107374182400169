import { render, staticRenderFns } from "./FlexibleCarousel2.vue?vue&type=template&id=ffe4a6ca&scoped=true&"
import script from "./FlexibleCarousel2.vue?vue&type=script&lang=ts&"
export * from "./FlexibleCarousel2.vue?vue&type=script&lang=ts&"
import style0 from "./FlexibleCarousel2.vue?vue&type=style&index=0&id=ffe4a6ca&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ffe4a6ca",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCarousel } from 'vuetify/lib/components/VCarousel';
import { VCarouselItem } from 'vuetify/lib/components/VCarousel';
import { VIcon } from 'vuetify/lib/components/VIcon';
installComponents(component, {VBtn,VCard,VCarousel,VCarouselItem,VIcon})
